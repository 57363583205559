import React from 'react'
import { Image, Header } from 'semantic-ui-react'

const SideImage = () => {
  return (
    <div
      className="side-image"
      style={{
        backgroundImage: `url("https://dexter-resource.s3.amazonaws.com/side-image.png")`,
      }}
    >
      <Image
        size="small"
        src={`https://dexter-resource.s3.amazonaws.com/dexterlogo_trans.png`}
        className="logo"
      />
      <Header as="h2" className="footer-text">
        Share Insights
        <br />
        Advance Precision Medicine
        <br />
        Monetize Your Expertise
      </Header>
      <Header as="h5" className="sub-footer-text">
        Dexter provides an exclusive channel for life science professionals and
        a diverse array of stakeholders playing a role in driving innovation in
        precision medicine to share their insights with industry leaders
      </Header>
    </div>
  )
}

export default SideImage
