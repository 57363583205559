import React, { useEffect } from 'react';
import {
  BrowserRouter, Route, Switch, useLocation,
} from 'react-router-dom';
import SideImage from './SideImage';
import { Grid, Header, Button } from 'semantic-ui-react';
import './App.scss';
import 'semantic-ui-css/semantic.min.css';

const NEW_URL = "https://dashboard.dexterexperts.com";

const Page = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${NEW_URL}${location.pathname}`;
    }, 5000);
  }, [location.pathname]);
  return (
    <div id="auth">
      <Grid divided="vertically" stackable>
        <Grid.Row>
          <Grid.Column width={9} only="computer tablet">
            <SideImage />
          </Grid.Column>
          <Grid.Column width={7} className="right">
            <Header as="h1" className="text-message">
              We have moved to another site!
              <Header.Subheader>
                We will redirect you in 5 seconds...<br /><br />
                If not, please click below or login at {' '}
                <a href={NEW_URL}>{NEW_URL}</a>
              </Header.Subheader>
            </Header>
            <Button
              secondary
              size="huge"
              onClick={() => window.open(`${NEW_URL}/login`)}
            >
              LOGIN
            </Button>
            <Button
              secondary
              size="huge"
              onClick={() => window.open(`${NEW_URL}/signup`)}
            >
              SIGN UP
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={Page} />
      <Route component={Page} />
    </Switch>
  </BrowserRouter>
);

export default App;
